import * as React from "react"
import { Link } from "gatsby"
import { Button } from "antd"
import { StoreContext } from "../context/store-context"
import Layout from "../components/layout"
import { LineItem } from "../components/line-item"
import { getShopifyImage } from "gatsby-source-shopify"
import { formatPrice } from "../utils/format-price"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  // table,
  // wrap,
  // totals,
  // grandTotal,
  // summary,
  // checkoutButton,
  // collapseColumn,
  // labelColumn,
  // imageHeader,
  // productHeader,
  // emptyStateContainer,
  // emptyStateHeading,
  // emptyStateLink,
  footer,
  title,
} from "./cart.module.css"
import PageContent from "../components/page-content"
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import {
  Table,
  Tag,
  Tooltip,
  Space,
  Typography,
  Row,
  Col,
  InputNumber,
  Modal,
  Badge,
} from "antd"
import debounce from "lodash.debounce"
import { NumericInput } from "../components/numeric-input"
const { Title, Text } = Typography
const { confirm } = Modal

export default function CartPage() {
  const {
    checkout,
    loading,
    redirectToCheckout,
    customerAdjustedPrice,
    updateLineItem,
    removeLineItem,
  } = React.useContext(StoreContext)
  const [cartItems, setCartItems] = React.useState([])

  const [cartSubtotal, setCartSubtotal] = React.useState(0)
  const [originalCartSubTotal, setOriginalSubTotal] = React.useState(0)
  const [originalCartTotal, setOriginalTotal] = React.useState(0)
  const [retailSavings, setRetailSavings] = React.useState(0)
  const [cartTotal, setCartTotal] = React.useState(0)

  React.useEffect(() => {
    if (checkout) {
      const items = checkout.lineItems.map((item, key) => {
        const variantPriceInfo = customerAdjustedPrice(item.variant.sku)
          ? {
              price: customerAdjustedPrice(item.variant.sku),
              originalPrice: item.variant.price,
            }
          : {
              price: item.variant.price,
            }
        return {
          ...item,
          variant: { ...item.variant, ...variantPriceInfo },
          key,
        }
      })
      setCartItems(items)
      const totals = items.reduce(
        (t, item) => {
          return {
            subTotal:
              t.subTotal + parseFloat(item.variant.price) * item.quantity,
            // savings:
            //   t.savings +
            //   (parseFloat(item.variant.originalPrice ?? item.variant.price) -
            //     parseFloat(item.variant.price) * item.quantity),
          }
        },
        { subTotal: 0, savings: 0 }
      )
      setCartSubtotal(formatPrice("USD", totals.subTotal))
      if (checkout.subtotalPriceV2) {
        setCartTotal(
          formatPrice(
            "USD",
            totals.subTotal + parseFloat(checkout.totalTaxV2.amount)
          )
        )
        setOriginalSubTotal(
          formatPrice(
            checkout.subtotalPriceV2.currencyCode,
            checkout.subtotalPriceV2.amount
          )
        )
        setRetailSavings(
          formatPrice("USD", checkout.subtotalPriceV2.amount - totals.subTotal)
        )
      }
      if (checkout.totalPriceV2) {
        // setCartTotal(
        //   formatPrice(
        //     checkout.totalPriceV2.currencyCode,
        //     checkout.totalPriceV2.amount
        //   )
        // )
        setOriginalTotal(
          formatPrice(
            checkout.totalPriceV2.currencyCode,
            checkout.totalPriceV2.amount
          )
        )
      }
    }
  }, [checkout, customerAdjustedPrice])

  // console.log(cartItems)
  // const emptyCart = checkout.lineItems.length === 0
  const uli = debounce(
    (itemId, value, checkoutId) => updateLineItem(checkoutId, itemId, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback(
    (id, value) => {
      return uli(id, value, checkout.id)
    },
    [checkout, uli]
  )

  const emptyCart = cartItems.length === 0
  const columns = [
    {
      title: null,
      dataIndex: "image",
      key: "image",
      width: 100,
      render: (_, { variant, quantity }) => {
        const variantImage = {
          ...variant.image,
          originalSrc: variant.image.src,
        }
        // console.log(variantImage)
        const image = getShopifyImage({
          image: variantImage,
          layout: "constrained",
          crop: "contain",
          width: 64,
          height: 64,
        })

        return image ? (
          <Badge
            count={quantity}
            // size="small"
            // status="default"
            color="#727272e6"
            offset={[-14, 12]}
          >
            {/* <CartIconSvg /> */}
            {/* {CartIcon} */}
            {/* <Icon component={CartIconSvg} style={{ color: "#FFFFFFA6" }} /> */}
            <GatsbyImage
              key={variantImage.src}
              image={image}
              alt={variantImage.altText ?? variant.title}
            />
          </Badge>
        ) : null
      },
    },
    {
      title: "Product",
      dataIndex: "title",
      key: "title",
      render: (text, record) => {
        return (
          <Space direction="vertical">
            <Title level={5} className={title}>
              {text}
            </Title>
            <Text>
              {record.variant.title != "Default Title"
                ? record.variant.title
                : ""}
            </Text>
          </Space>
        )
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
      // render: (text, record) => record.variant.price,
      render: (text, record) => {
        // const total = parseFloat(record.variant.price) * record.quantity
        const total = formatPrice(
          record.variant.priceV2.currencyCode,
          // record.variant.price,
          Number(record.variant.price)
        )
        const originalPrice = formatPrice(
          record.variant.priceV2.currencyCode,
          // record.variant.price,
          Number(record.variant.originalPrice)
        )
        return (
          <Space direction="vertical">
            {originalPrice && (
              <Tooltip title="Suggested retail">
                <Text delete disabled>
                  {originalPrice}
                </Text>
              </Tooltip>
            )}
            <Text>{total}</Text>
          </Space>
        )
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "right",
      render: (quantity, record) => {
        return (
          <InputNumber
            aria-label="Quantity"
            onChange={(value) => {
              // console.log({ value, id: record.id })
              return debouncedUli(record.id, value)
            }}
            defaultValue={quantity}
            min={1}
            //  max="20"
          />
        )
        // return (
        //   <NumericInput
        //     disabled={loading}
        //     value={quantity}
        //     aria-label="Quantity"
        //     // onIncrement={doIncrement}
        //     // onDecrement={doDecrement}
        //     // onChange={(e) => handleQuantityChange(e.currentTarget.value)}
        //   />
        // )
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (text, record) => {
        // const total = parseFloat(record.variant.price) * record.quantity
        const total = formatPrice(
          record.variant.priceV2.currencyCode,
          Number(record.variant.price) * record.quantity
        )
        const originalPrice = formatPrice(
          record.variant.priceV2.currencyCode,
          // record.variant.price,
          Number(record.variant.originalPrice) * record.quantity
        )
        return (
          <Space direction="vertical">
            {originalPrice && (
              <Tooltip title="Suggested retail">
                <Text delete disabled>
                  {originalPrice}
                </Text>
              </Tooltip>
            )}
            <Text>{total}</Text>
          </Space>
        )
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      align: "right",
      colSpan: 4,
      render: (_, { title, id }) => {
        return (
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              // removeLineItem(checkout.id, id)
              confirm({
                title: "Are you sure remove this item?",
                icon: <ExclamationCircleOutlined />,
                content: `Would you like to remove ${title} from your cart?`,
                okText: "Yes",
                okType: "danger",
                // okButtonProps: {
                //   disabled: true,
                // },
                cancelText: "No",
                onOk() {
                  // console.log('OK');
                  removeLineItem(checkout.id, id)
                },
                onCancel() {
                  // console.log('Cancel');
                },
              })
            }}
          >
            Remove
          </Button>
        )
      },
    },
  ]

  const handleCheckout = async () => {
    await redirectToCheckout()
  }

  const checkoutButton = (
    <Button
      onClick={handleCheckout}
      loading={loading}
      // className={checkoutButton}
      type="primary"
    >
      Checkout
    </Button>
  )
  return (
    <Layout>
      <PageContent>
        <Table
          columns={columns}
          dataSource={cartItems}
          pagination={false}
          title={() => <Title level={2}>Your cart</Title>}
          footer={() =>
            !emptyCart && (
              <Row className={footer} gutter={[16, 16]}>
                {originalCartSubTotal != cartSubtotal && (
                  <>
                    <Col span={20}>Suggest retail total</Col>
                    <Col span={4}>
                      <Text>{originalCartSubTotal}</Text>
                    </Col>
                    <Col span={20}>Savings</Col>
                    <Col span={4}>
                      <Text>{retailSavings}</Text>
                    </Col>
                  </>
                )}
                <Col span={20}>Subtotal</Col>
                <Col span={4}>
                  <Text strong>{cartSubtotal}</Text>
                </Col>
                <Col span={20}>Taxes</Col>
                <Col span={4}>
                  {formatPrice(
                    checkout.totalTaxV2.currencyCode,
                    checkout.totalTaxV2.amount
                  )}
                </Col>
                <Col span={20}>Shipping</Col>
                <Col span={4}>Calculated at checkout</Col>
                <Col span={20}>Total Price</Col>
                <Col span={4}>
                  <Space direction="vertical">
                    {originalCartTotal && originalCartTotal !== cartTotal && (
                      <Tooltip title="Suggested retail">
                        <Text delete disabled>
                          {originalCartTotal}
                        </Text>
                      </Tooltip>
                    )}
                    <Text strong>{cartTotal}</Text>
                  </Space>
                </Col>
                <Col span={24}>{checkoutButton}</Col>
              </Row>
            )
          }
        />
        {/* <pre>{JSON.stringify(cartItems, null, 2)}</pre> */}
      </PageContent>
    </Layout>
  )
}
